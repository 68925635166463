.uiCard{
    max-width: 400px;
    min-width: 362px;
    max-height: 480px;
    min-height: 422px;
    padding: 40px 24px;
    background-color: #FFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    text-align: center;
    z-index: 1000;
}

.loginForm{
    width: 100%;
}

.btnLogin{
    display: block;
    width: 100%;
    background-color: #355AB3;

}

.loginControll{
    width: 100%;
    height:40px;
}

.titleLogin{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 28px;
}

.subtitleLogin{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #595963;
}

footer{
    margin-top: 40px;
}

.copyRight{
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
}