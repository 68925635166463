@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400&display=swap);
.uiCard{
    max-width: 400px;
    min-width: 362px;
    max-height: 480px;
    min-height: 422px;
    padding: 40px 24px;
    background-color: #FFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    text-align: center;
    z-index: 1000;
}

.loginForm{
    width: 100%;
}

.btnLogin{
    display: block;
    width: 100%;
    background-color: #355AB3;

}

.loginControll{
    width: 100%;
    height:40px;
}

.titleLogin{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 28px;
}

.subtitleLogin{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #595963;
}

footer{
    margin-top: 40px;
}

.copyRight{
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
}
.containerLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #F5F5FC;
    background-repeat: no-repeat;
    overflow: hidden;
}

.rightImage{
    position: absolute;
    top: 0; 
    right: 0;
}

.leftImage{
    position: absolute;
    bottom: 0; 
    left: 0;
}
main{
    background-color: #FAFAFA;
}

path {
    filter:drop-shadow(0px 1px #cccccc)
  }


.divCreateUser{
    width: 100%;
    margin-top: 27px;
    padding-bottom: 35px;
}

.allFomrGroup{
    width: 100%;
    display: flex;
    flex-wrap: wrap;  
}

.formGroup{
    width: 276px;
    min-width: 276px;
    margin-left: 48px;
    margin-top: 52px;
}

.userControl{
    width: 100%;
}

.infrastructureContainer {
    padding:20px 45px 35px 45px;
  }
  
  .leftBox {
    margin-right: 25px;
  }
  
  .textLabel {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }
  
  .textLabelUpload {
    font-size: 16px;
    color: #262626;
  }
  
  .inputForm {
    min-width: 280px;
  }
  
  .separated {
    margin-bottom: 15px;
  }
  
  .containerTable {
    border: 1px solid #d9d9d9;
  }
  
  .footerButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .buttonBlue {
    background: #345ab3;
    border-color: #345ab3;
  }
  

.containerEditSchema{
    margin:20px 45px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 35px;
}

.boxLeft{
    width: 30%;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    max-height: 500px;
}

.boxFomLeft form{
    width: 100%;
}

.schemaEditControl{
    width: 100%;
}

.formControl{
    margin-top: 15px;
}

.deleteControls{
    margin-top: 52px;
}

.boxFomRight{
    width: 60%;
    min-width: 600px;
    max-width: 1000px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
}
  

.divTableUsers{
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 57px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
}

.allFormGroup{
    width: 100%;
    display: flex;
    flex-wrap: wrap;  
    margin-top: 30px;
    padding:20px;
}

.divSwich{
    margin-top: 20px;
    margin-left: 20px;
}

.divTransfer{
    display: flex;
    justify-content:center;
    margin-bottom: 45px;
}



.CatalogListContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 35px;
}

.leftCard {
  margin-right: 25px;
  width: 40%;
  min-width: 400px
}

.textLabel {
  font-weight: 400;
  font-size: 14px;
  color: #595959;
}

.textLabelUpload {
  font-size: 16px;
  color: #262626;
}

.inputForm {
  min-width: 280px;
}

.tableScrollable {
  border-radius: 10px;
}

.separated {
  margin-bottom: 15px;
}

.tableContainer {
  border: 1px solid #d9d9d9;
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.buttonBlue {
  background: #345ab3;
  border-color: #345ab3;
}

.divEditAgreements{
    margin:20px 45px 0 45px;
    width: 100%;
    padding-bottom: 35px;
    padding-right: 45px;
}

.divFormEditAgreement{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.mainForm{
    width: 30%;
    min-width:350px;
    max-width: 500px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
}

.divInfrastructure{
    width: 55%;
    min-width: 650px;
    max-width: 1000px;

}

.containerMonthAsistances{
    margin:20px 45px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 35px;
}

.boxAssistanceLeft{
    width: 300px;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    max-height: 300px;
}

.boxAssistanceRight{
    width: 450px;
    min-width: 450px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
    max-height: 500px;
}

.individualMonthContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.individualMonth{
    width: 200px;
    margin-right: 3px;
}

.monthField {
    width: 100%;
    padding: 8px;
}

.serch-unicBeneficiary form{
    display: flex;
    flex-direction: row;
    align-items:initial;
    justify-content: space-between;
}
.containerNonRequireds{
    margin:45px 45px 0 45px;
}

.btnRequireds{
    background-color: #345AB3;
    border-color: #345AB3;
    margin-top: 33px;
}

.boxFomLeft{
    width: 30%;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    max-height: 450px;
}


.CompleteMicroDataContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 35px;
}

.leftCard {
  margin-right: 25px;
  max-width: 500px;
}

.textLabel {
  font-weight: 400;
  font-size: 14px;
  color: #595959;
}

.inputForm {
  width: 100%;
}

.separated {
  margin-bottom: 15px;
}

.uploadComponent {
  margin-top: 30px;
  width: 100%;
}

.textLabelUpload {
  font-size: 16px;
  color: #262626;
}

.rightCard {
  min-width: 500px;
  max-width: 700px;
}

.itemRegistry {
  margin-right: 20px;
  font-size: 12px;
}

.titleReport {
  font-weight: 400px;
  font-size: 14px;
  color: #262626;
  margin-top: 20px;
}

.hrLine {
  margin: 40px 0px;
}

.containerEditSchema{
    margin:20px 45px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.boxFormLeft{
    width: 30%;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    max-height: 150px;
}

.formControl{
    margin-top: 15px;
}

.boxFomRight{
    width: 60%;
    min-width: 450px;
    max-width: 550px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
}
.paginationPage {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: lightgray;
    border: none;
    margin: 5px;
    cursor: pointer;
}

.currentPage {
    background-color: #1890ff;
    color: white;
}

.paginationPage:hover {
    filter: brightness(1.1);
    transition: ease-in-out 0.1s;
}
.accionsV2 {
    justify-content: end !important;
    display: flex !important;
    flex-direction: row-reverse !important;
}

.popupV2 {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    max-width: 900px !important;
    min-width: 500px !important;
}

.pie {
    display: flex !important;
    flex-direction: column !important;
    max-width: 900px !important;
    min-width: 500px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    place-content: start !important;

}

.pie small {
    word-wrap: break-word !important;
}

.accionsV2 button {
    background-color: #355AB3 !important;
    border-radius: 16px !important;
    margin: 4px 4px !important;
    padding: 10px 20px !important;
}

.accionsV2 .buttonCancel {
    background-color: white !important;
    color: black !important;
    border: 2px solid #D9D9D9 !important;
}

.modal-container {
    display: flex;
    width: 100%;
    height: 100%;
    place-items: center;
    place-content: center;
}

.modal-header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header-title {
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 24px;
    color: black;
}

.modal-header-title {
    display: flex;
    width: 100%;
    place-content: start;

}

.modal-header-content {
    text-align: left;
    margin-left: 50px;
}

.modal-header-content p:nth-child(1) {
    margin-left: 20px !important;
    max-width: 500px !important;
}

.small-msg {
    margin-left: 0px !important;
    max-width: 500px !important;
}

.header-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #FAAD14;
    display: flex;
    place-content: center;
    place-items: center;
    color: #FAAD14;
    font-size: 26px;
    margin-right: 20px;
}

.header-icon-success {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #52C41A;
    display: flex;
    place-content: center;
    place-items: center;
    color: #52C41A;
    font-size: 26px;
    margin-right: 20px;
}


.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #52C419;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 26px;
    /* Ajusta este valor según el nuevo tamaño */
    height: 26px;
    /* Ajusta este valor según el nuevo tamaño */
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #52C419;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    30% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}

.results-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

}

.result-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
}

.result-id,
.result-date {
    padding: 5px 10px;

}

.result-id {
    min-width: 528px;
    max-width: 528px;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-date {
    flex-shrink: 0;
    text-align: right;
}
.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    background-color: #FFF;
    height:64px;
}

nav .appName{
    font-weight: 600;
    font-size: 16px;
}
body{
    font-family: 'Source Sans Pro', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
