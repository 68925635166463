.CompleteMicroDataContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 35px;
}

.leftCard {
  margin-right: 25px;
  max-width: 500px;
}

.textLabel {
  font-weight: 400;
  font-size: 14px;
  color: #595959;
}

.inputForm {
  width: 100%;
}

.separated {
  margin-bottom: 15px;
}

.uploadComponent {
  margin-top: 30px;
  width: 100%;
}

.textLabelUpload {
  font-size: 16px;
  color: #262626;
}

.rightCard {
  min-width: 500px;
  max-width: 700px;
}

.itemRegistry {
  margin-right: 20px;
  font-size: 12px;
}

.titleReport {
  font-weight: 400px;
  font-size: 14px;
  color: #262626;
  margin-top: 20px;
}

.hrLine {
  margin: 40px 0px;
}
