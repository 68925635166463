.infrastructureContainer {
    padding:20px 45px 35px 45px;
  }
  
  .leftBox {
    margin-right: 25px;
  }
  
  .textLabel {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }
  
  .textLabelUpload {
    font-size: 16px;
    color: #262626;
  }
  
  .inputForm {
    min-width: 280px;
  }
  
  .separated {
    margin-bottom: 15px;
  }
  
  .containerTable {
    border: 1px solid #d9d9d9;
  }
  
  .footerButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .buttonBlue {
    background: #345ab3;
    border-color: #345ab3;
  }
  