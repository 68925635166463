
.containerMonthAsistances{
    margin:20px 45px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 35px;
}

.boxAssistanceLeft{
    width: 300px;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    max-height: 300px;
}

.boxAssistanceRight{
    width: 450px;
    min-width: 450px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
    max-height: 500px;
}

.individualMonthContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.individualMonth{
    width: 200px;
    margin-right: 3px;
}

.monthField {
    width: 100%;
    padding: 8px;
}

.serch-unicBeneficiary form{
    display: flex;
    flex-direction: row;
    align-items:initial;
    justify-content: space-between;
}