.containerLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #F5F5FC;
    background-repeat: no-repeat;
    overflow: hidden;
}

.rightImage{
    position: absolute;
    top: 0; 
    right: 0;
}

.leftImage{
    position: absolute;
    bottom: 0; 
    left: 0;
}