
.containerEditSchema{
    margin:20px 45px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 35px;
}

.boxLeft{
    width: 30%;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    max-height: 500px;
}

.boxFomLeft form{
    width: 100%;
}

.schemaEditControl{
    width: 100%;
}

.formControl{
    margin-top: 15px;
}

.deleteControls{
    margin-top: 52px;
}

.boxFomRight{
    width: 60%;
    min-width: 600px;
    max-width: 1000px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
}
  
