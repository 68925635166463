.divTableUsers{
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 57px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
}

.allFormGroup{
    width: 100%;
    display: flex;
    flex-wrap: wrap;  
    margin-top: 30px;
    padding:20px;
}

.divSwich{
    margin-top: 20px;
    margin-left: 20px;
}

.divTransfer{
    display: flex;
    justify-content:center;
    margin-bottom: 45px;
}


