.paginationPage {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: lightgray;
    border: none;
    margin: 5px;
    cursor: pointer;
}

.currentPage {
    background-color: #1890ff;
    color: white;
}

.paginationPage:hover {
    filter: brightness(1.1);
    transition: ease-in-out 0.1s;
}