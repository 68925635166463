.containerNonRequireds{
    margin:45px 45px 0 45px;
}

.btnRequireds{
    background-color: #345AB3;
    border-color: #345AB3;
    margin-top: 33px;
}

.boxFomLeft{
    width: 30%;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    max-height: 450px;
}

