.accionsV2 {
    justify-content: end !important;
    display: flex !important;
    flex-direction: row-reverse !important;
}

.popupV2 {
    width: fit-content !important;
    max-width: 900px !important;
    min-width: 500px !important;
}

.pie {
    display: flex !important;
    flex-direction: column !important;
    max-width: 900px !important;
    min-width: 500px !important;
    height: fit-content !important;
    place-content: start !important;

}

.pie small {
    word-wrap: break-word !important;
}

.accionsV2 button {
    background-color: #355AB3 !important;
    border-radius: 16px !important;
    margin: 4px 4px !important;
    padding: 10px 20px !important;
}

.accionsV2 .buttonCancel {
    background-color: white !important;
    color: black !important;
    border: 2px solid #D9D9D9 !important;
}

.modal-container {
    display: flex;
    width: 100%;
    height: 100%;
    place-items: center;
    place-content: center;
}

.modal-header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header-title {
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 24px;
    color: black;
}

.modal-header-title {
    display: flex;
    width: 100%;
    place-content: start;

}

.modal-header-content {
    text-align: left;
    margin-left: 50px;
}

.modal-header-content p:nth-child(1) {
    margin-left: 20px !important;
    max-width: 500px !important;
}

.small-msg {
    margin-left: 0px !important;
    max-width: 500px !important;
}

.header-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #FAAD14;
    display: flex;
    place-content: center;
    place-items: center;
    color: #FAAD14;
    font-size: 26px;
    margin-right: 20px;
}

.header-icon-success {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #52C41A;
    display: flex;
    place-content: center;
    place-items: center;
    color: #52C41A;
    font-size: 26px;
    margin-right: 20px;
}


.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #52C419;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 26px;
    /* Ajusta este valor según el nuevo tamaño */
    height: 26px;
    /* Ajusta este valor según el nuevo tamaño */
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #52C419;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    30% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}

.results-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

}

.result-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
}

.result-id,
.result-date {
    padding: 5px 10px;

}

.result-id {
    min-width: 528px;
    max-width: 528px;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-date {
    flex-shrink: 0;
    text-align: right;
}