.divCreateUser{
    width: 100%;
    margin-top: 27px;
    padding-bottom: 35px;
}

.allFomrGroup{
    width: 100%;
    display: flex;
    flex-wrap: wrap;  
}

.formGroup{
    width: 276px;
    min-width: 276px;
    margin-left: 48px;
    margin-top: 52px;
}

.userControl{
    width: 100%;
}
