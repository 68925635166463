.divEditAgreements{
    margin:20px 45px 0 45px;
    width: 100%;
    padding-bottom: 35px;
    padding-right: 45px;
}

.divFormEditAgreement{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.mainForm{
    width: 30%;
    min-width:350px;
    max-width: 500px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
}

.divInfrastructure{
    width: 55%;
    min-width: 650px;
    max-width: 1000px;

}