.containerEditSchema{
    margin:20px 45px 0 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.boxFormLeft{
    width: 30%;
    max-width: 400px;
    min-width: 320px;
    padding: 20px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    max-height: 150px;
}

.formControl{
    margin-top: 15px;
}

.boxFomRight{
    width: 60%;
    min-width: 450px;
    max-width: 550px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    padding: 20px;
}